@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    font-base: 16px;
    overscroll-behavior: none;
    scrollbar-gutter: stable;
  }

  body {
    min-height: 100vh;
    @apply bg-ghost-white-100;
    overscroll-behavior: none;
  }

  *:focus {
    @apply outline-secondary-40;
  }

  @media (-webkit-device-pixel-ratio: 1.25) {
    :root {
      zoom: 0.8;
    }
  }

  /* Add shadcn variables here */
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }

  * {
    @apply border-border;
  }
}

@layer components {
  .apexcharts-tooltip {
    @apply bg-white-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 border-0 rounded-lg shadow-lg !important;
  }

  .apexcharts-tooltip .apexcharts-tooltip-title {
    @apply py-2 px-4 bg-gray-100 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-500 !important;
  }

  .apexcharts-xaxistooltip {
    @apply text-gray-500 border-0 bg-white-100 dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg !important;
  }

  .apexcharts-tooltip .apexcharts-tooltip-text-y-value {
    @apply dark:text-white-100;
  }

  .apexcharts-xaxistooltip-text {
    @apply font-medium text-sm !important;
  }

  .apexcharts-xaxistooltip:before,
  .apexcharts-xaxistooltip:after {
    @apply border-0 !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    @apply bg-green-500 !important;
  }

  .reset-button {
    @apply border-none p-0 cursor-pointer outline-inherit;
  }

  .apexcharts-toolbar {
    @apply z-0 right-[unset] !important;
  }

  .striped div:nth-child(even) {
    @apply bg-gray-100;
  }

  .apexcharts-yaxis-texts-g {
    @apply transform-none !important;
  }

  .apexcharts-toolbar {
    @apply absolute right-auto left-0 p-0 !important;
  }

  .apexcharts-zoom-icon {
    @apply hidden;
  }

  .apexcharts-reset-icon {
    @apply ml-10 !important;
  }

  .text-container::after {
    @apply content-[''] absolute left-0 right-0 bottom-0 h-24 bg-gradient-to-b from-transparent to-[#ffffff];
  }

  .no-spin-buttons::-webkit-outer-spin-button,
  .no-spin-buttons::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-spin-buttons {
    -moz-appearance: textfield;
  }
}
